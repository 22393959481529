import React, { Fragment, useEffect, useState } from "react";
import Script from "next/script";
import { Router } from "next/router";
import Link from "next/link";
import Header from "components/Header";
import Footer from "components/Footer";
import "../styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Notification from "../components/UI/Notification";
import CookiePopup from "../components/UI/CookiePopup";
import { setUserProperty, track } from "../utils/amplitude";
import MAIN_NOTIFY_BAR from "../../mainNotifyBar";
import qs from "qs";
import { parseCookies, setCookie } from "nookies";

const GTM_ID = "GTM-58QHBBJ";
const AMPLITUDE_API_KEY = "e8b10c067da52ffc0fc456301bc77f03";

let oldRouteValue = "";
Router.events.on("routeChangeStart", (e) => {
  if (oldRouteValue !== e) {
    track("page_loaded");
    oldRouteValue = e;
  }
});

function MyApp({ Component, pageProps }) {
  const cookies = parseCookies();
  const [acceptedCookies, setAcceptedCookies] = useState(
    cookies["cookies"] === "1"
  );
  useEffect(() => {
    track("page_loaded");
    const isVisited = window.localStorage.getItem("first_visit");
    if (!isVisited) {
      track("first_visit");
      window.localStorage.setItem("first_visit", 1);
    }
    const search = window.location.search.replace("?", "");
    const params = qs.parse(search);
    if (window.location.search.indexOf("utm_source") > -1) {
      setUserProperty({
        last_utm_campaign: params?.utm_campaign || "-",
        last_utm_content: params?.utm_content || "-",
        last_utm_medium: params?.utm_medium || "-",
        last_utm_source: params?.utm_source || "-",
      });
      setCookie(null, "utm_metric", search, {
        path: "/",
        maxAge: 2592000,
      });
      track("user_source_attributed", params);
    }
    const listener = (e) => {
      if (e.detail === "1") {
        setAcceptedCookies(true);
      }
    };
    window.addEventListener("accept-cookies", listener);
    return () => {
      window.removeEventListener("accept-cookies", listener);
    };
  }, []);
  return (
    <div>
      {MAIN_NOTIFY_BAR.show && (
        <Notification
          show
          text={
            <Fragment>
              <span>{MAIN_NOTIFY_BAR.title}</span>
              <Link href={MAIN_NOTIFY_BAR.link.href}>
                <a
                  onClick={() => track("notification_bar_link_clicked")}
                  target="_blank"
                >
                  {MAIN_NOTIFY_BAR.link.title}
                </a>
              </Link>
            </Fragment>
          }
        />
      )}
      <Header />
      <Component {...pageProps} />
      <Footer />
      <div suppressHydrationWarning={true}>
        {process.browser && <CookiePopup />}
      </div>
      {acceptedCookies && (
        <>
          <Script
            id="gtm-js"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
            }}
          />
          <Script
            id="amplitude-js"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
            ;r.type="text/javascript"
            ;r.integrity="sha384-VuGgAcmMrGHihvjXxxBVMIqoDFXc8/PO9q/08kCgq4Wn1iPnSmUbI3xhXaFozVFv"
            ;r.crossOrigin="anonymous";r.async=true
            ;r.src="https://cdn.amplitude.com/libs/amplitude-8.18.1-min.gz.js"
            ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){console.log(
            "[Amplitude] Error: could not load SDK")}};var s=t.getElementsByTagName("script"
            )[0];s.parentNode.insertBefore(r,s);function i(e,t){e.prototype[t]=function(){
            this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
            var o=function(){this._q=[];return this};var a=["add","append","clearAll",
            "prepend","set","setOnce","unset","preInsert","postInsert","remove"];for(
            var c=0;c<a.length;c++){i(o,a[c])}n.Identify=o;var l=function(){this._q=[]
            ;return this};var u=["setProductId","setQuantity","setPrice","setRevenueType",
            "setEventProperties"];for(var p=0;p<u.length;p++){i(l,u[p])}n.Revenue=l;var d=[
            "init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut",
            "setVersionName","setDomain","setDeviceId","enableTracking",
            "setGlobalUserProperties","identify","clearUserProperties","setGroup",
            "logRevenueV2","regenerateDeviceId","groupIdentify","onInit","onNewSessionStart"
            ,"logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId",
            "getDeviceId","getUserId","setMinTimeBetweenSessionsMillis",
            "setEventUploadThreshold","setUseDynamicConfig","setServerZone","setServerUrl",
            "sendEvents","setLibrary","setTransport"];function v(t){function e(e){
            t[e]=function(){t._q.push([e].concat(Array.prototype.slice.call(arguments,0)))}}
            for(var n=0;n<d.length;n++){e(d[n])}}v(n);n.getInstance=function(e){e=(
            !e||e.length===0?"$default_instance":e).toLowerCase();if(
            !Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])}
            return n._iq[e]};e.amplitude=n})(window,document);
            
            amplitude.getInstance().init("${AMPLITUDE_API_KEY}")
            amplitude.getInstance("second").init("e49b0f56c3d26bb88b62df89cb7a209a")
          `,
            }}
          />
        </>
      )}
    </div>
  );
}

export default MyApp;
