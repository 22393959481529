import React, { useCallback, useState } from "react";
import Image from "next/image";
import cn from "classnames";
import styles from "./styles.module.scss";

const Dropdown = ({ children, items, large, type }) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => setOpen(!open), [open]);

  return (
    <div className={cn(styles.dropdown, styles[type])}>
      <div className={styles.header} onClick={onToggle}>
        <div className={styles.link}>{children}</div>
        <svg
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33206 7.86427L9.11941 4.07685C9.20707 3.98925 9.25537 3.87232 9.25537 3.74763C9.25537 3.62294 9.20707 3.50601 9.11941 3.41841L8.84056 3.13949C8.65886 2.958 8.36354 2.958 8.18212 3.13949L5.00173 6.31988L1.81781 3.13596C1.73015 3.04837 1.61328 3 1.48866 3C1.36391 3 1.24704 3.04837 1.1593 3.13596L0.880526 3.41488C0.792858 3.50255 0.744561 3.61942 0.744561 3.7441C0.744561 3.86879 0.792858 3.98572 0.880526 4.07332L4.67133 7.86427C4.75928 7.95207 4.8767 8.0003 5.00152 8.00002C5.12683 8.0003 5.24418 7.95207 5.33206 7.86427Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.55294 8.08533C5.40594 8.23221 5.20892 8.3128 5.00153 8.31253C4.79447 8.3128 4.59747 8.23211 4.45054 8.08541L0.659642 4.29438C0.659612 4.29435 0.659673 4.29441 0.659642 4.29438C0.512979 4.14778 0.432061 3.95111 0.432061 3.7441C0.432061 3.53701 0.512948 3.34055 0.6595 3.19396L0.938508 2.91482C1.08502 2.76854 1.28137 2.6875 1.48866 2.6875C1.69585 2.6875 1.89224 2.76856 2.0387 2.91491L5.00173 5.87794L7.96111 2.91856C7.96112 2.91855 7.96109 2.91858 7.96111 2.91856C8.26467 2.61493 8.7578 2.61514 9.0614 2.9184L9.34029 3.19735C9.48694 3.34394 9.56787 3.54063 9.56787 3.74763C9.56787 3.95464 9.48704 4.15122 9.34038 4.29782L5.55294 8.08533ZM9.11941 4.07685L5.33206 7.86427C5.24418 7.95207 5.12683 8.0003 5.00152 8.00002C4.8767 8.0003 4.75928 7.95207 4.67133 7.86427L0.880526 4.07332C0.792858 3.98572 0.744561 3.86879 0.744561 3.7441C0.744561 3.61942 0.792858 3.50255 0.880526 3.41488L1.1593 3.13596C1.24704 3.04837 1.36391 3 1.48866 3C1.61328 3 1.73015 3.04837 1.81781 3.13596L5.00173 6.31988L8.18212 3.13949C8.36354 2.958 8.65886 2.958 8.84056 3.13949L9.11941 3.41841C9.20707 3.50601 9.25537 3.62294 9.25537 3.74763C9.25537 3.87232 9.20707 3.98925 9.11941 4.07685Z"
            fill="black"
          />
        </svg>
      </div>
      <div className={cn("menu", styles.menu, { _open: open })}>
        <div className={styles.arrow} onClick={onToggle}>
          <svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33206 7.86427L9.11941 4.07685C9.20707 3.98925 9.25537 3.87232 9.25537 3.74763C9.25537 3.62294 9.20707 3.50601 9.11941 3.41841L8.84056 3.13949C8.65886 2.958 8.36354 2.958 8.18212 3.13949L5.00173 6.31988L1.81781 3.13596C1.73015 3.04837 1.61328 3 1.48866 3C1.36391 3 1.24704 3.04837 1.1593 3.13596L0.880526 3.41488C0.792858 3.50255 0.744561 3.61942 0.744561 3.7441C0.744561 3.86879 0.792858 3.98572 0.880526 4.07332L4.67133 7.86427C4.75928 7.95207 4.8767 8.0003 5.00152 8.00002C5.12683 8.0003 5.24418 7.95207 5.33206 7.86427Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.55294 8.08533C5.40594 8.23221 5.20892 8.3128 5.00153 8.31253C4.79447 8.3128 4.59747 8.23211 4.45054 8.08541L0.659642 4.29438C0.659612 4.29435 0.659673 4.29441 0.659642 4.29438C0.512979 4.14778 0.432061 3.95111 0.432061 3.7441C0.432061 3.53701 0.512948 3.34055 0.6595 3.19396L0.938508 2.91482C1.08502 2.76854 1.28137 2.6875 1.48866 2.6875C1.69585 2.6875 1.89224 2.76856 2.0387 2.91491L5.00173 5.87794L7.96111 2.91856C7.96112 2.91855 7.96109 2.91858 7.96111 2.91856C8.26467 2.61493 8.7578 2.61514 9.0614 2.9184L9.34029 3.19735C9.48694 3.34394 9.56787 3.54063 9.56787 3.74763C9.56787 3.95464 9.48704 4.15122 9.34038 4.29782L5.55294 8.08533ZM9.11941 4.07685L5.33206 7.86427C5.24418 7.95207 5.12683 8.0003 5.00152 8.00002C4.8767 8.0003 4.75928 7.95207 4.67133 7.86427L0.880526 4.07332C0.792858 3.98572 0.744561 3.86879 0.744561 3.7441C0.744561 3.61942 0.792858 3.50255 0.880526 3.41488L1.1593 3.13596C1.24704 3.04837 1.36391 3 1.48866 3C1.61328 3 1.73015 3.04837 1.81781 3.13596L5.00173 6.31988L8.18212 3.13949C8.36354 2.958 8.65886 2.958 8.84056 3.13949L9.11941 3.41841C9.20707 3.50601 9.25537 3.62294 9.25537 3.74763C9.25537 3.87232 9.20707 3.98925 9.11941 4.07685Z"
              fill="black"
            />
          </svg>
        </div>
        {items?.map((e, i) => (
          <div
            className={cn(styles.item, e.img === "grow" && styles.item_grow, {
              large: large,
            })}
            key={i}
          >
            <Image
              src={`/images/icons/product/${e.img}.svg`}
              alt={e.img}
              width="48"
              height="48"
              loading="lazy"
              className={styles.image}
            />
            <div>
              <strong>
                {e?.link ? (
                  <a
                    href={e.link}
                    target={e.blank ? "_blank" : "_self"}
                    rel={e.rel}
                    onClick={e?.onClick}
                  >
                    {e.title}
                  </a>
                ) : (
                  e.title
                )}
              </strong>
              <span>{e.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
