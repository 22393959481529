export const track = (name, params = {}, url = window.location.href) => {
  setTimeout(() => {
    if (window.amplitude) {
      try {
        window.amplitude.getInstance().logEvent(name, {
          url,
          ...params,
        });
        window.amplitude.getInstance("second").logEvent(`landing_${name}`, {
          url,
          ...params,
        });
      } catch (e) {
        // console.error("event debug: ", name, params, e);
      }
    }
  }, 500);
};

export const setUserProperty = (params = {}) => {
  setTimeout(() => {
    if (window.amplitude) {
      window.amplitude.getInstance().setUserProperties(params);
    }
  }, 1000);
};
