import React, { useState } from "react";
import styles from "./Sidebar.module.scss";
import Link from "next/link";
import { ReactComponent as CloseSVG } from "./assets/close.svg";
import { ReactComponent as ArrowSVG } from "./assets/arrow.svg";
import { ReactComponent as BackSVG } from "./assets/back.svg";
import { links } from "./index";

const MenuItem = ({ title, link, sub = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.item}>
      {sub.length > 0 && (
        <>
          <div className={styles.clickable} onClick={() => setIsOpen(true)}>
            <span>{title}</span>
            <ArrowSVG />
          </div>
          <div className={`${styles.submenu} ${isOpen && styles.open}`}>
            <div className={styles.back} onClick={() => setIsOpen(false)}>
              <BackSVG />
            </div>
            {sub.map((el, i) => (
              <div className={styles.link}>
                {el?.link ? (
                  <Link href={el?.link} key={i}>
                    <label>{el.title}</label>
                  </Link>
                ) : (
                  <label>{el.title}</label>
                )}
                {el?.description && (
                  <div className={styles.sublink}>{el.description}</div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      {sub.length === 0 && (
        <Link href={link}>
          <div className={styles.clickable}>
            <span>{title}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

const Sibebar = (props) => {
  const { onClose } = props;
  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <Link href="/">
            <img
              width="120"
              height="24"
              src="/images/logo-header.svg"
              alt="Apphud"
              className={styles.logo}
            />
          </Link>
          <div onClick={onClose}>
            <CloseSVG />
          </div>
        </div>
        <div className={styles.menu}>
          {links.map((el, i) => (
            <MenuItem key={i} title={el.title} sub={el.sub} link={el?.link} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sibebar;
