import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import SvgIcon from "./icon.svg";
import { parseCookies, setCookie } from "nookies";
import { track } from "../../../utils/amplitude";
import Button from "../Button";

export default function CookiePopup() {
  const cookies = parseCookies();
  const [show, setShow] = useState(cookies["cookies"] !== "1");
  const handleClose = (value) => {
    const evt = new CustomEvent("accept-cookies", { detail: value });
    setShow(false);
    setCookie(null, "cookies", value, {
      path: "/",
      maxAge: 2592000,
    });
    track("cookies_popup_closed");
    window.dispatchEvent(evt);
  };
  return (
    <div className={cn(styles.cookiePopup, { show })}>
      <div className={styles.card}>
        <SvgIcon />
        <div className={styles.content}>
          <label>This website uses cookies</label>
          <p>
            By clicking on the «Allow all» button, you accept the use of cookies
            on this website. We use cookies to measure and analyze our traffic
            as well as for marketing purposes (e.g. for retargeting ads). You
            can edit your preferences at any time. For more information, refer
            to our{" "}
            <a href="https://legal.apphud.com/privacy" target="_blank">
              privacy policy
            </a>
            .
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            transparent={true}
            title="Reject all"
            onClick={() => {
              handleClose("0");
              track("cookies_popup_rejected_all");
            }}
          />
          <Button
            title="Allow all"
            onClick={() => {
              handleClose("1");
              track("cookies_popup_allowed_all");
            }}
          />
        </div>
      </div>
    </div>
  );
}
